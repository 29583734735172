import React from "react";
import {
  Container,
  Jumbotron,
  Button,
  Row,
  Col,
  Image,
  Carousel,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import SuccessIcon from "./assets/success.svg";

import "../App.css";
function Consultancy() {
  const line = {
    width: 250,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const paragraphs = {
    fontSize: 16,
    padding: 5,
    fontWeight: "500",
  };

  const history = useHistory();
  return (
    <Container>
      <Jumbotron
        style={{
          background: "white",
        }}
      >
        <h1 style={{ fontWeight: "700", textAlign: "center" }}>
          Our Consultancy Process
        </h1>
        <div style={line}></div>
        <br />
        <br />
        <Carousel
          style={{ background: "#1c74b9", borderRadius: 5, height: 300 }}
        >
          <Carousel.Item
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: 100,
            }}
          >
            <h2>First Step</h2>
            <br />
            <p style={paragraphs}>
               We boost performance with tailored consultancy, covering all steps
              from assessment to implementation.
            </p>
          </Carousel.Item>
          <Carousel.Item
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: 100,
            }}
          >
            <h2>Second Step</h2>
            <p style={paragraphs}>
              <br />
              We’ll work closely with you to understand your business and to
              ensure the most optimal solution is selected.
            </p>
          </Carousel.Item>
          <Carousel.Item
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: 100,
            }}
          >
            <h2>Assesment</h2>
            <p style={paragraphs}>
              <br />
              We perform an assessment of your organizations solution needs.
            </p>
          </Carousel.Item>
          <Carousel.Item
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: 100,
            }}
          >
            <h2> Proposal</h2>
            <p style={paragraphs}>
              <br />We present a recommendation on the best possible solution.</p>
          </Carousel.Item>
          <Carousel.Item
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: 100,
            }}
          >
            <h2> Implementation</h2>
            <p style={paragraphs}>
              <br />
              We integrate the solution in your organization and with your
              partners
            </p>
          </Carousel.Item>
          <Carousel.Item
            style={{
              color: "white",
              textAlign: "center",
              paddingTop: 50,
            }}
          >
            <h2>Success</h2>
            <Image src={SuccessIcon} style={{ height: 150 }} />
          </Carousel.Item>
        </Carousel>

        <br />
        <br />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => history.push("/more-consultancy")}
            style={{
              width: "max-content",
            }}
          >
            Learn more on Concultancy
          </Button>
        </div>
      </Jumbotron>
    </Container>
  );
}

export default Consultancy;
