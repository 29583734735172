import React from "react";
import { Jumbotron, Container, Row, Col, Button, Image } from "react-bootstrap";

import "../App.css";

function StrategicPartners() {
  const centerTexts = {
    background: "white",
  };

  const mainHeader = {
    fontWeight: "700",
    // color: "whitesmoke",
    textAlign: "center",
  };
  const sideHeader = {
    fontWeight: "700",
    color: "#1C74B9",
    // color: "whitesmoke",
  };
  const line = {
    width: 200,
    height: 3,
    background: "black",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const content = {
    marginTop: 30,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 500,
  };

  return (
    <div className="first-page">
      <div style={{ paddingTop: 30 }}>
        <Container>
          <Jumbotron
            style={{
              background: "white",
            }}
          >
            <h1
              style={{
                fontWeight: "700",
                // color: "whitesmoke",
                textAlign: "center",
              }}
            >
              Strategic Partners
            </h1>
            <div style={line}></div>

            <div style={content}>
              <ol>
                <Row style={{ padding: 5 }}>
                  <Col sm>
                    <li> B&M Consulting </li>
                    <li>Trustagic Consulting – Insurance solutions</li>
                    <li> Rehmani & Co. Chartered accountants</li>
                    <li>RAS Aviation Services – Aviation consulting </li>
                    <li>Vivid Visionz </li>
                    <li> Kodex Technologies (Pvt) Ltd </li>
                    <li> Crea8Web PK </li>
                    <li>InventorX Technologies </li>
                    <li> Lahore Business Solutions </li>
                    <li>Logos19 (Digital & Professional Agency) </li>
                    <li> E-Rich Tech </li>
                  </Col>

                  <Col sm>
                    <li> WhyReadBetter (USA) </li>
                    <li> Bridggit (Financial & management advisory) </li>
                    <li> AX Guru (Guru Group) </li>
                    <li>
                      {" "}
                      IT Experts Solutions SEO & Digital Services (Lahore){" "}
                    </li>
                    <li> CubeXS Weatherly (Pvt) Ltd </li>
                    <li> Muniff Ziauddin & Co Chartered Accountants </li>
                    <li> 2Checkout.com Inc </li>
                    <li> Data One Technology (Australia) </li>
                    <li> Treehouse Exatec Pvt Ltd </li>
                    <li> RFID Pakistan </li>
                    <li> Arcana Info Pvt Ltd </li>
                    <li> Shufti Pro (AML and KYC) </li>
                  </Col>
                </Row>
              </ol>
            </div>
          </Jumbotron>
        </Container>
      </div>
    </div>
  );
}

export default StrategicPartners;
