import React from "react";
import { useHistory } from "react-router-dom";
import { Jumbotron, Container, Row, Col, Button, Image } from "react-bootstrap";
import CreateIcon from "@material-ui/icons/Create";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import HighlightIcon from "@material-ui/icons/Highlight";

import AIIcon from "./assets/AI.svg";
import WebIcon from "./assets/web.svg";
import SolutionIcon from "./assets/solution.svg";
import EngineeringIcon from "./assets/engineering.svg";
import DevIcon from "./assets/design.svg";
import TransformIcon from "./assets/transform.svg";
import ConsultingIcon from "./assets/consulting.svg";
import IOT from "./assets/iot.svg";

import "../App.css";

function About() {
  const centerTexts = {
    background: "white",
  };

  const mainHeader = {
    fontWeight: "700",
    // color: "whitesmoke",
    textAlign: "center",
  };
  const sideHeader = {
    fontWeight: "700",
    color: "#1C74B9",
    // color: "whitesmoke",
  };
  const paragraphs = {
    fontSize: "16px",
    fontWeight: "500",
    // color: "whitesmoke",
  };
  const line = {
    width: 200,
    height: 3,
    background: "black",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const services = {
    width: 250,
    height: 220,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "0px 0px 10px black",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  };
  const AboutImages = {
    marginBottom: 10,
    height: 150,
    paddingTop: 20,
  };
  const history = useHistory();
  return (
    <div className="first-page">
      {/* <div class="inverted-triangle2" style={{ paddingTop: 50 }}></div> */}
      <div style={{ paddingTop: 30 }}>
        <Container>
          <Jumbotron style={centerTexts}>
            <h1 style={mainHeader}> Details About Us</h1>
            <div style={line}></div>
            <br />
            <h3 style={sideHeader}>
              {" "}
              Simplify the way we WORK, LIVE and CONNECT{" "}
            </h3>
            <p style={paragraphs}>
              We’re a technology powerhouse with over 16 years of extensive
              experience in the digital, monitoring software development field.
              We believe that cost-effective digital and systems solutions
              should be the norm and accessible to all. Our expertise ranges
              from software, big data, factory 4.0, integrated solution, online
              digital marketing to telecommunications, AV solutions, IT and
              security systems. As a turnkey/bespoke solutions provider we leave
              no stone unturned in the pursuit of perfection in the field.{" "}
            </p>
            <p style={paragraphs}>
              Our focus is simplifying the way we work, live and connect around
              the world. Using AI, Machine Learning and NLP our team of
              recognized Engineers and Developers along with our functional
              business experts identify needs and design solutions.
            </p>
            <p style={paragraphs}>
              The answers to the worlds needs, is at your fingertip.
            </p>{" "}
            {/* <br /> */}
            <br />
            <br />
            <div className="aboutus">
              <div className="box">
                <div className="boxContent">
                  <CreateIcon className="icon" />
                  <h1 className="title">Vision</h1>
                  <p className="desc">Solving the unsolvable </p>
                </div>
              </div>
              <div className="box">
                <div className="boxContent">
                  <HourglassEmptyIcon className="icon" />
                  <h1 className="title">Mission</h1>
                  <p className="desc">
                    Be Futuristic, Simplify The Way We Work, Live And Connect
                  </p>
                </div>
              </div>
              <div className="box">
                <div className="boxContent">
                  <HighlightIcon className="icon" />
                  <h1 className="title">Global Market</h1>
                  <p className="desc">
                    Global market size is crossing USD 4.73 Trillion at CAGR 41%
                  </p>
                </div>
              </div>
            </div>
          </Jumbotron>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              paddingBottom: 30,
            }}
          >
            <h1 style={mainHeader}> What do we do?</h1>
            <br />
            <div className="team-container">
              <div style={services} className="image-services icon">
                <Image src={AIIcon} style={AboutImages} />
                <p style={paragraphs}>Artificial Intelligence </p>
              </div>
              <div style={services} className="image-services icon">
                <Image src={WebIcon} style={AboutImages} />

                <p style={paragraphs}>Web & App development </p>
              </div>
              <div style={services} className="image-services icon">
                <Image src={SolutionIcon} style={AboutImages} />

                <p style={paragraphs}>Bespoke Solutions </p>
              </div>
              <div style={services} className="image-services icon">
                <Image src={EngineeringIcon} style={AboutImages} />

                <p style={paragraphs}>Engineering </p>
              </div>

              <div style={services} className="image-services icon">
                <Image src={DevIcon} style={AboutImages} />

                <p style={paragraphs}>Design & Development </p>
              </div>
              <div style={services} className="image-services icon">
                <Image src={ConsultingIcon} style={AboutImages} />

                <p style={paragraphs}>Consulting </p>
              </div>
              <div style={services} className="image-services icon">
                <Image src={TransformIcon} style={AboutImages} />

                <p style={paragraphs}>Digital Transformation </p>
              </div>
              <div style={services} className="image-services icon">
                <Image src={IOT} style={AboutImages} />

                <p style={paragraphs}>RFID & IOT </p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* <div class="triangle2"></div> */}
    </div>
  );
}

export default About;
