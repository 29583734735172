import React from "react";
import { Jumbotron, Container, Image } from "react-bootstrap";
import Logo from "../components/assets/logoWithoutText.png";
import Navigation from "./NavBar";
import Footer from "./Footer";
// import Logo from '../components/assets/logo.png';
import "../App.css";
class LandingPage extends React.Component {
  render() {
    const centerTexts = {
      // textAlign: "center",
      // background: "#cb1d58",
      background: "white",
      marginBottom: 40,
      marginTop: 110,
      // border: "1px solid #aaa",
    };

    const mainHeader = {
      fontWeight: "700",
      // color: "whitesmoke",
    };
    const sideHeader = { fontWeight: "600" };
    const paragraphs = {
      fontSize: "20px",
      fontWeight: "500",
      // color: "whitesmoke",
      // width: "95%",
    };
    const Img = {
      height: 350,
      width: 400,
      float: "right",
      padding: 20,

      // boxShadow: "1px 5px 7px 1px black",
    };

    return (
      <>
        <div className="landing-page">
          {/* <div class="inverted-triangle"></div> */}
          <div
            className="main-header"
            style={{
              // background: "#1c74b9",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 40,
            }}
          >
            <Container>
              <Jumbotron style={centerTexts}>
                <Image src={Logo} style={Img} className="image-style" fluid />
                {/* <br /> */}
                <br />

                <h1 style={mainHeader}>Hello world!</h1>
                <h2 style={sideHeader}>This is 5CORE NEXGEN.</h2>
                <h4 style={{ color: "#E91087" }}>Be futuristic</h4>
                <br />
                <h3 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  We help businesses choose the most optimal solution
                </h3>
                <br />
                <p style={paragraphs}>
                  5Core NextGen Solutions (Pvt) Ltd, is an international
                  Information and Communication Technology company, specialized
                  in Artificial Intelligence solutions. <br /> <br />
                  Our offices are in Business Bay (Dubai) and Karachi
                  (Pakistan). Our team consists of diversified and well
                  recognized Engineers and Developers of A.I., Machine Learning,
                  Deep Learning, Cognitive Science, Neural Networking, NLP and
                  automation software, as well as highly professional business
                  techno-functional experts, chartered accountants, diplomats
                  and economists. Our focus is simplifying the way we work, live
                  and connect around the world. Using AI, Machine Learning and
                  NLP our team of recognized Engineers and Developers along with
                  our functional business experts identify needs and design
                  solutions. The answers to the worlds needs, is at your
                  fingertip.
                  {/* </p>
              <p style={paragraphs}> */} <br /> <br />
                  We help businesses by facilitating access to professional big
                  data transformation, RFID, IOT, web solutions and services
                  boosting performance with tailored consultancy, we’re covering
                  all the steps from assessment to implementation. We work
                  closely with our clients ensuring the most optimal solution is
                  selected for their business.
                </p>
              </Jumbotron>
            </Container>
            {/* <div class="triangle" style={{ background: "white" }}></div> */}
          </div>
        </div>
      </>
    );
  }
}
export default LandingPage;
