import React, { Component } from "react";
// import { useHistory } from "react-router-dom";

import {
  Container,
  Jumbotron,
  Image,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import "../App.css";

import IndustryPic1 from "./assets/industrypic1.png";
import IndustryPic2 from "./assets/industrypic2.png";
import IndustryPic3 from "./assets/industrypic3.png";
import IndustryPic4 from "./assets/industrypic4.png";
import IndustryPic5 from "./assets/industrypic5.png";
import IndustryPic6 from "./assets/industrypic6.png";
import IndustryPic7 from "./assets/industrypic7.png";
import IndustryPic8 from "./assets/industrypic8.png";
import IndustryPic9 from "./assets/industrypic9.png";
import IndustryPic10 from "./assets/industrypic10.png";
import IndustryPic11 from "./assets/industrypic11.png";
import IndustryPic12 from "./assets/industrypic12.png";
import IndustryPic13 from "./assets/industrypic13.png";
import IndustryPic14 from "./assets/industrypic14.png";

export class Industry extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: "",
    selectedPlace: "",

    show: false,
    close: false,
  };

  render() {
    const line = {
      width: 250,
      height: 3,
      background: "#404042",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 20,
    };
    const divider = {
      width: "90%",
      height: 0.5,
      background: "lightgrey",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 10,
      marginTop: 10,
    };

    const paragraphs = {
      fontSize: "20px",
      fontWeight: "500",
    };
    const button = { marginBottom: 10, width: 240, height: 80 };
    function MouseOver(event) {
      event.target.style.color = "white";
    }
    function MouseOut(event) {
      event.target.style.color = "black";
    }
    return (
      <>
        {/* <div class="inverted-triangle"></div> */}
        <div className="first-page">
          <div style={{ paddingTop: 30 }}>
            <Container>
              <Jumbotron
                style={{
                  background: "white",
                }}
              >
                <h1
                  style={{
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  Industry 4.0
                </h1>
                <div style={line}></div>
                <div className="team-container">
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show1: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Manufacturing
                    </Button>
                    <Modal
                      show={this.state.show1}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          src={IndustryPic1}
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Manufacturing{" "}
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show1: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show2: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Supply Chain
                    </Button>
                    <Modal
                      show={this.state.show2}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic2}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Supply Chain
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show2: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show3: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Access Control System
                    </Button>
                    <Modal
                      show={this.state.show3}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic3}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Access Control System
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show3: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show4: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Theft Monitoring
                    </Button>
                    <Modal
                      show={this.state.show4}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic4}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Theft Monitoring
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show4: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show5: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Apparel
                    </Button>
                    <Modal
                      show={this.state.show5}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic5}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Apparel
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show5: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show6: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Footwear
                    </Button>
                    <Modal
                      show={this.state.show6}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic6}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Footwear
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show6: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show7: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Warehouse
                    </Button>
                    <Modal
                      show={this.state.show7}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic7}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Warehouse
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show7: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show8: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Documents
                    </Button>
                    <Modal
                      show={this.state.show8}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic8}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Documents
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show8: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>{" "}
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show9: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Livestock
                    </Button>
                    <Modal
                      show={this.state.show9}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic9}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Livestock
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show9: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show10: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Library
                    </Button>
                    <Modal
                      show={this.state.show10}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic10}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Library
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show10: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show11: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Headcount/Attendance
                    </Button>
                    <Modal
                      show={this.state.show11}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic11}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Headcount/Attendance
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show11: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show12: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Virtual Ecosystem
                    </Button>
                    <Modal
                      show={this.state.show12}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            // height: 300,
                            // width: "90%",
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic12}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Virtual Ecosystem
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show12: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>{" "}
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show13: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Automobile Industry
                    </Button>
                    <Modal
                      show={this.state.show13}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic13}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Automobile Industry
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show13: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div>
                    <Button
                      variant="none"
                      style={{ width: 150 }}
                      onClick={() => this.setState({ show14: true })}
                      style={button}
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                    >
                      RFID in Hospital
                    </Button>
                    <Modal
                      show={this.state.show14}
                      animation={true}
                      centered
                      dialogClassName="my-modal"
                    >
                      <Modal.Header style={{ borderBottom: 0 }}>
                        <Modal.Title></Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Image
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: 300,
                          }}
                          fluid
                          src={IndustryPic14}
                        />
                      </Modal.Body>
                      <Modal.Footer style={{ borderTop: 0 }}>
                        {" "}
                        <h5 style={{ display: "block", margin: "0 auto" }}>
                          {" "}
                          RFID in Hospital
                        </h5>
                        <Button
                          style={{ width: 150 }}
                          onClick={() => this.setState({ show14: false })}
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>{" "}
                </div>
                {/* <br />
            <p style={paragraphs}>
              <div style={divider}></div>
              <div style={divider}></div>
              <div style={divider}></div>
              <div style={divider}></div>
              <div style={divider}></div>
              <div style={divider}></div>
              <div style={divider}></div>
              <div style={divider}></div>
            </p>
            <br /> 
            <Button onClick={() => history.goBack()}>Back </Button> */}
              </Jumbotron>
            </Container>
          </div>
          {/* <div class="triangle" style={{ background: "white" }}></div> */}
        </div>
      </>
    );
  }
}
export default Industry;
