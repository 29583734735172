import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Jumbotron, Button, Row, Col, Image } from "react-bootstrap";
import PurchaseIcon from "./assets/purchase.svg";
import MediaIcon from "./assets/media.svg";
import AccountingIcon from "./assets/accounting.svg";

import WarehouseIcon from "./assets/warehouse.svg";

import ProjectIcon from "./assets/project.svg";
import IntelligenceIcon from "./assets/BI.svg";

// import SupportIcon from "./assets/support.svg";
// import OperationIcon from "./assets/operation.svg";
// import FinanceIcon from "./assets/finance.svg";
import "../App.css";
function InvestorRelations() {
  const line = {
    width: 250,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const content = {
    marginTop: 30,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    fontWeight: 500,
  };
  const sideHeader = {
    fontWeight: "700",
    fontSize: 22,
    color: "#a01b50",
  };
  const paragraphs = {
    fontSize: "18px",
    fontWeight: "500",
  };
  const services = {
    width: 250,
    height: 220,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxShadow: "0px 0px 15px #aaa",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 20,
  };
  const SolutionImage = {
    height: 150,
    paddingTop: 20,
    marginBottom: 10,
  };
  const history = useHistory();
  return (
    <>
      <Container>
        <Jumbotron
          style={{
            background: "white",
          }}
        >
          <h1 style={{ fontWeight: "700", textAlign: "center" }}>
            Our Solutions
          </h1>
          <div style={line}></div>
          <div>
            <br />
            <h3 style={{ color: "#1C74B9" }}> CAGVIR NextGen ERP </h3>
            <p style={paragraphs}>
              Cagvir is NextGen ERP brand of 5Core NextGen Solutions (Pvt) Ltd
              <li>Deal in all kind of customization and integrations. </li>
              <li>
                {" "}
                NextGen ERP is designed for Industry Revolution 4.0{" "}
              </li>{" "}
              <li>Team of dedicated NextGen experts. </li>{" "}
              <li> More than 5 years’ experience. </li>{" "}
              <li>
                Having 10+ successful deployment experience which include below
                industries: <br />
                <div style={{ marginLeft: 20 }}>
                  - Retail Industry <br /> - Manufacture Industry <br /> -
                  Pharma Industry <br />
                  - Service Industry <br />- Water Purificaton Industry <br /> -
                  Automobile Industry{" "}
                </div>
              </li>
            </p>
          </div>
          <div
            className="team-container"
            style={{
              textAlign: "center",
            }}
          >
            <div style={services} className="image">
              <Image src={PurchaseIcon} style={SolutionImage} />
              <p style={{ fontWeight: "bold" }}>
                CRM, Sales / Purchase Management
              </p>
            </div>
            <div style={services} className="image">
              <Image src={MediaIcon} style={SolutionImage} />
              <p style={{ fontWeight: "bold" }}> Marketing & Social Media</p>
            </div>
            <div style={services} className="image">
              <Image src={AccountingIcon} style={SolutionImage} />
              <p style={{ fontWeight: "bold" }}> Accounting & Reporting</p>
            </div>
            <div style={services} className="image">
              <Image src={WarehouseIcon} style={SolutionImage} />
              <p style={{ fontWeight: "bold" }}> Warehouse & Inventory</p>
            </div>
            <div style={services} className="image">
              <Image src={ProjectIcon} style={SolutionImage} />
              <p style={{ fontWeight: "bold" }}>HR & Project Management</p>
            </div>
            <div style={services} className="image">
              <Image src={IntelligenceIcon} style={SolutionImage} />
              <p style={{ fontWeight: "bold" }}> Business Intelligence</p>
            </div>
          </div>
          <br />
          <br />
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button onClick={() => history.push("/more-nexgenerp")}>
              Learn more about ERP
            </Button>
          </div>
        </Jumbotron>
      </Container>
    </>
  );
}

export default InvestorRelations;
