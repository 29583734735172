import React from "react";
import { useHistory } from "react-router-dom";

import { Container, Jumbotron, Image, Button } from "react-bootstrap";
import InsideNavigation from "./InsideNavBar";
import Footer from "./Footer";
import "../App.css";

import Image1 from "./assets/image1.png";
import Image2 from "./assets/image2.png";

function MoreERP() {
  const line = {
    width: 250,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
  };

  const img = {
    width: "auto",
    height: 350,
    margin: 10,
    padding: 5,
    boxShadow: "3px 3px 10px 3px #aaa",
    borderRadius: 7,
  };
  const paragraphs = {
    fontSize: "16px",
    fontWeight: "500",
  };
  const divider = {
    width: "90%",
    height: 0.5,
    background: "lightgrey",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    marginTop: 10,
  };
  const history = useHistory();

  return (
    <>
      <InsideNavigation />
      <div
        className="second-page"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container style={{ paddingTop: 170 }}>
          <Jumbotron
            style={{
              background: "white",
            }}
          >
            <h1
              style={{
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              More About NexGen ERP
            </h1>
            <div style={line}></div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Image src={Image2} fluid />
              <br />
              <Image src={Image1} fluid />
            </div>
            <br />
            <h4 style={{ color: "#1c74b9" }}>
              {" "}
              Why should you choose NextGen ERP?
            </h4>
            <p style={paragraphs}>
              NextGen ERP is based on a technology stack that is modern and up
              to date. These technologies continue to be developed and adapted
              to the latest paradigms, and so is NextGen ERP.
            </p>
            <div style={divider}></div>
            <p style={paragraphs}>
              {" "}
              NextGen ERP offers a wealth of functionalities with over a 1000
              modules that can be deployed to meet your business needs. NextGen
              is actively being developed and supported by the community and the
              stack of functionality continues to increase. Finding an ERP
              system that has the functionality you require is important.
            </p>
            <div style={divider}></div>
            <p style={paragraphs}>
              {" "}
              NextGen ERP is not only an easy to use software, it also highly
              flexible. NextGen ERP can be tailored to fit your company’s
              specific business requirements. In a highly competitive and fast
              changing world, companies like yours need to constantly push and
              innovate to keep pace with changing business scenarios. This can
              only be achieved with the right tools.{" "}
            </p>
            <div style={divider}></div>
            <p style={paragraphs}>
              <h4 style={{ color: "#1c74b9" }}>
                Top 5 reasons for using the NextGen CRM & ERP solution in your
                business:{" "}
              </h4>
              o Easy to learn and use <br />
              o Modern and up to date user interface <br />
              o Comprehensive integrated functionality <br />
              o Can be customized and tailored to your needs <br />
              o Costefficient license pricing and low TCO <br />
            </p>
            <div style={divider}></div>
            <p style={paragraphs}>
              Another improvement with an integrated system such as NextGen ERP
              is the availability of on demand and up to date reports that could
              take days, weeks, or months to produce in other circumstances.
              Often companies make the mistake to see an ERP system as a cost
              center and fail to see the potential for these improvements
            </p>
            <div style={divider}></div>
            <br />
            <h1
              style={{
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Questions About NexGen ERP
            </h1>
            <div style={line}></div>
            <h4 style={{ color: "#1c74b9" }}>
              {" "}
              Which other cost do I have to consider for the implementation of
              NextGen?
            </h4>
            <p style={paragraphs}>
              The basic installation of the system is included in the service
              cost. Other implementation costs such as configuration, employee
              training, data migration, detailed adaptation, development will
              vary depending on the size of the implementation and the usage of
              the system. Get in touch with us for a detailed quotation.
            </p>
            <div style={divider}></div>
            <h4 style={{ color: "#1c74b9" }}>
              {" "}
              Can NextGen ERP be integrated with other third party systems and
              solutions?
            </h4>
            <p style={paragraphs}>
              Yes, in our live demo section you can test such a integration
              between NextGen ERP & BI Analytics. Our implementation team has
              already made connections to well known systems such as Magento,
              Amazon, EBay, WooCommerce, PayPal, QuickBooks Accounting,
              MailChimp and many more.
            </p>
            <div style={divider}></div>
            <h4 style={{ color: "#1c74b9" }}>
              {" "}
              How long does it generally take to complete the implementation of
              NextGen ERP?
            </h4>
            <p style={paragraphs}>
              Implementations with default modules can take somewhere between 1
              to 2 months , this will vary depending on the type of business and
              on a "case to case" basis. Another part of the NextGen ERP
              implementation is the customization this is the part where the
              estimation solely depend on the modifications you want.
            </p>
            <div style={divider}></div>
            <h4 style={{ color: "#1c74b9" }}>
              {" "}
              Can I access NextGen ERP from a mobile device when I’m out of the
              office?
            </h4>
            <p style={paragraphs}>
              Yes, with the NextGen Mobile app you can access messages,
              contacts, notes, orders, track your inventory, manage your
              meetings at your fingertips and you can use them anywhere to keep
              watch on your business. NextGen Mobile is a native mobile
              application available for Android and iPhone.
            </p>
            <div style={divider}></div>
            <br />
            <Button onClick={() => history.goBack()} style={{ width: 150 }}>
              Back{" "}
            </Button>
          </Jumbotron>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default MoreERP;
