import React from "react";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { Tooltip } from "@material-ui/core";
import Github from "@material-ui/icons/GitHub";
import Fb from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import Linkedin from "@material-ui/icons/LinkedIn";
import Call from "@material-ui/icons/WhatsApp";

import ScrollToTop from "react-scroll-up";
import UpwardIcon from "./assets/upward.svg";

function Footer() {
  const centerTexts = {
    background: "#1C74B9",
    width: "100%",
  };
  const paragraphs = {
    fontSize: "16px",
    fontWeight: "500",
    paddingBottom: 10,

    color: "white",
  };

  const bigParagraphs = {
    fontSize: 17,
    fontWeight: "500",
    paddingBottom: 10,

    cursor: "pointer",
    color: "white",
  };
  const history = useHistory();
  return (
    <>
      <div style={centerTexts}>
        {/* <div class="inverted-triangle2"></div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            paddingTop: 30,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Row>
            <Col sm>
              <h4
                style={{
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Important Links
              </h4>
              <br />
              <p
                onClick={() => history.push("/")}
                style={bigParagraphs}
                className="footer-hover"
              >
                Home
              </p>
              {/* <p
                onClick={() => history.push("/about")}
                style={bigParagraphs}
                className="footer-hover"
              >
                About
              </p> */}
              <p
                onClick={() => history.push("/more-nexgenerp")}
                style={bigParagraphs}
                className="footer-hover"
              >
                More on ERP
              </p>
              <p
                onClick={() => history.push("/more-consultancy")}
                style={bigParagraphs}
                className="footer-hover"
              >
                More on Consultancy
              </p>
              <p
                onClick={() => history.push("/more-intellectual-properties")}
                style={bigParagraphs}
                className="footer-hover"
              >
                More on Intellectual Properties{" "}
              </p>
            </Col>
            <Col sm>
              <h4
                style={{
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Contact Us
              </h4>
              <br />
              <p style={paragraphs}>
                <span style={{ fontSize: 17 }}> Email: </span>{" "}
                info@5cnextgen.com{" "}
              </p>
              <p style={paragraphs}>
                {" "}
                <span style={{ fontSize: 17 }}> Cell:</span> <br /> UAE: +971 52
                558 8132 <br /> Pakistan: +92 333 3247535 / +92 342 2343223
              </p>
              <p style={paragraphs}>
                <span style={{ fontSize: 17 }}> Address: </span>
                <br />
                Head Office: Suite No 2, Ground Floor, 15 C ،3rd Sehar
                Commercial Ln, Lane 3 DHA, Karachi.
                <br />
                RO: Office 28, 3 rd floor, Snow White Complex, Shahra-e-Faisal,
                Karachi, 75350 <br />
                3504 Manazel Alsafa Business Bay, Dubai
              </p>
              <div style={{ flexDirection: "row" }}>
                {/* <Tooltip title="Github">
                  <Github
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://github.com/")
                    }
                  />
                </Tooltip> */}
                <Tooltip title="Facebook">
                  <Fb
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href =
                        "https://www.facebook.com/5cnextgensol/")
                    }
                  />
                </Tooltip>
                <Tooltip title="Instagram">
                  <Instagram
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://instagram.com/")
                    }
                  />
                </Tooltip>
                <Tooltip title="Linkedin">
                  <Linkedin
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://www.linkedin.com/")
                    }
                  />
                </Tooltip>
                <Tooltip title="Whatsapp">
                  <Call
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://wa.me/+923333247535")
                    }
                  />
                </Tooltip>
              </div>
            </Col>{" "}
          </Row>
        </div>
        <p
          style={{
            color: "white",
            paddingTop: 30,
            paddingBottom: 20,
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          {" "}
          Copyright &copy; 2021 5Core NexGen. All Rights Reserved
        </p>
      </div>
      <ScrollToTop showUnder={0} duration={1000}>
        <button
          style={{
            width: 60,
            height: 60,
            borderRadius: "50%",
            padding: 15,
          }}
          className="scroll-btn"
        >
          <img
            src={UpwardIcon}
            style={{
              display: "inline-block",
            }}
          />
        </button>
      </ScrollToTop>
    </>
  );
}

export default Footer;
