import React, { useState } from "react";
import { Container, Jumbotron, Image, Row, Col } from "react-bootstrap";

import "../App.css";
function ExecutiveTeam() {
  const line = {
    width: 300,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
  };

  const img = {
    width: "auto",
    height: 250,
    margin: 10,
    padding: 5,
    marginLeft: "auto",
    marginRight: "auto",
  };
  return (
    <Container>
      <Jumbotron
        style={{
          textAlign: "center",
          background: "white",
        }}
      >
        <h1 style={{ fontWeight: "700" }}>Meet the Executive Team</h1>
        <div style={line}></div>
        <br />
        <div className="team-container">
          <div class="hovereffect">
            <h2> M. S. Siddiqui (CEO) </h2>
            <div class="overlay">
              <p>
                ACCA / MBA <br />
                Entrepreneurship/ICAEW
              </p>
            </div>
          </div>
          <div class="hovereffect">
            <h2> Sarosh Rizvi (CTO) </h2>
            <div class="overlay">
              <p>
                {" "}
                MBA MIS/ Mphil <br /> Ex-CEO of Media Bank Pakistan{" "}
              </p>
            </div>
          </div>{" "}
          <div class="hovereffect">
            <h2> Eliya Abbas (CMO) </h2>

            <div class="overlay">
              <p> MBA Entrepreneurship </p>
            </div>
          </div>{" "}
          <div class="hovereffect">
            <h2> Syed A. Ali Jaffery (Research Consultant) </h2>

            <div class="overlay">
              <p>PhD in Artificial Intelligence</p>
            </div>
          </div>{" "}
          <div class="hovereffect">
            <h2>
              {" "}
              Syed Adeel Bukhari <br /> (FCA){" "}
            </h2>
            <div class="overlay">
              <p>Director Business Functions</p>
            </div>
          </div>{" "}
          <div class="hovereffect">
            <h2> Shamas Ahmed </h2>
            <div class="overlay">
              <p>Director CAGVIR NextGen ERP Division</p>
            </div>
          </div>{" "}
          <div class="hovereffect">
            <h2> Shahid Iqbal </h2>
            <div class="overlay">
              <p>
                ACCA <br />
                Director Business Strategy
              </p>
            </div>
          </div>
        </div>
      </Jumbotron>
    </Container>
  );
}

export default ExecutiveTeam;
