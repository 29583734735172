import React from "react";

import { Navbar, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import Logo from "../components/assets/logoWithoutText.png";
import "../App.css";

class InsideNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false,
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  setNavClose = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    const navdesign = {
      background: "#404042",
      paddingLeft: 10,
      marginBottom: 40,
    };
    const logo = {
      fontSize: "26px",
      fontWeight: "600",
      display: "flex",
      alignItems: "center",
      color: " #1C74B9",
      paddingTop: 20,
      marginLeft: 20,
      paddingBottom: 10,
    };
    const navlinks = {
      fontSize: "14px",
      padding: "5px",
      margin: "5px",
      color: "white",
      borderRadius: "3px",

      fontWeight: "500",
      cursor: "pointer",
      textAlign: "center",
    };
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div style={{ width: "max-content" }}>
              <Navbar.Brand href="/" style={logo}>
                <Image
                  src={Logo}
                  style={{ width: 55, height: 50, marginRight: 10 }}
                />
                5CORE
                <span style={{ marginLeft: 5, color: "#E91087" }}> NEXGEN</span>
              </Navbar.Brand>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InsideNavBar;
