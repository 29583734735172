import React from "react";
import { useHistory } from "react-router-dom";

import { Container, Jumbotron, Button } from "react-bootstrap";
import InsideNavigation from "./InsideNavBar";
import Footer from "./Footer";
import "../App.css";

function MoreIP() {
  const line = {
    width: 200,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
  };
  const divider = {
    width: "90%",
    height: 0.5,
    background: "lightgrey",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    marginTop: 10,
  };

  const paragraphs = {
    fontSize: 16,
    fontWeight: 500,
  };
  const bigPara = {
    fontSize: 21,
    color: "#1c74b9",
    fontWeight: 600,
  };
  const history = useHistory();

  return (
    <>
      <InsideNavigation />

      <div
        className="second-page"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container style={{ paddingTop: 170 }}>
          <Jumbotron
            style={{
              background: "white",
            }}
          >
            <h1
              style={{
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Our Artificial Intelligence Based Solutions (Patented)
            </h1>

            <div style={line}></div>

            <br />
            <p style={paragraphs}>
              <span style={bigPara}> 1) LUCID: </span> What normally takes hours
              of a radiologist time looking at CT-scans to diagnose lung cancer,
              LUCID identifies lung cancer cells and provides a 3D
              reconstruction of a patient’s lungs from a normal CT-scan in 4
              minutes.
              <br />
              o It detects the tumor, measures the required values such as area,
              perimeter, diameter, centroid, roundness, indentations and
              calcification and provides type and location of tumor.
              <br />
              o LUCID technology can also be used to identify the COVID-19
              infection in 4 minutes.
              <br />
              o Finally, LUCID identifies false screening of a previously
              diagnosed disease, be it cancer or an infection such as COVID-19,
              via a CT-Scan.
              <br />o Holds a provisional patent in “3D Reconstruction of Lungs
              for Cancer Diagnosis” Application No. GB1913135.8 in UK.
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                2) Media Sentiment Analysis (MSA):{" "}
              </span>{" "}
              What do your customers think about your products and brands. MSA
              is not new, but a vital component to creating the customer
              experience. Our tool evaluates tone, intent and emotion. It is no
              longer a lucky guess, it provides the answers to simplifying the
              way we work, live and connect, minimizing the risk of a faulty
              assumptions. <br />
              WE didn’t stop at the customer’s, we went further to understanding
              employee engagement. Our Organizational Network dashboard provides
              management with information and solutions. Remote working has
              never been so easy.
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                3) Aviation (FOQA, MRO, In Flight VR, Flight Safety, System
                Integration): <br /> Meet Zullu
              </span>{" "}
              Holds a provisional patent in “Comparative, Competitive and
              Predictive Analysis using ADSB.” EFS ID: 33495241 in USA.
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                Comparison on the basis of:
              </span>
              <br />
              o Fuel Consumption Report by particular flight based on number of
              miles.
              <br />
              o Charge estimation based on number of miles, altitude and class.
              <br />
              o Delay Analytics & Estimation (time the flight delayed)
              <br />
              o Probability of improvements
              <br />
              o Integrated FOQA
              <br />o Integrated MRO (Real Time Monitoring to Supply Chain).
              <div style={divider}></div>
              <span style={bigPara}> 4) AI Fabric: </span> The Textile industry
              is labor intensive, from fabric design to manufacturing. AI Fabric
              is the leading edge technology that eliminates wasted time and
              costs for the furniture industry, apparel industry and accessory
              industry. The output provides intelligence that consumers want and
              at the price point. Like tech, fashion is forward-looking and
              cyclical. Holds a provisional patent in “Automatic Generation of
              Fabric Designing” Application No. US 62/848,002 in USA.
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                5) AI Human Collaborative Fashion:
              </span>{" "}
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                Mirror, mirror on the wall…..
              </span>
              <br />
              Designers spend endless hours designing fashion apparel only to
              have it become obsolete within days and months. Our mirror, mirror
              on the wall generates design using GANS. Shortening the lead time
              and production time enables designers to capitalize on trends real
              time.
              <br />
              Big fashion houses view this technology as the forefront of the
              fashion industry. Use this technology to design apparel. Use this
              technology for customers to “virtual” try on the apparel. Use this
              technology for increased sales and lower costs.
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                6) Poppy (AI for Babycare and Mother Care):
              </span>
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                Does her/his tummy hurt?
              </span>
              <br />
              Microbial colonization of the infant gut plays an important role
              in lifelong health. In healthy newborns, the gut microbiome
              composition shows large-scale longitudinal changes until age 3
              years, when it settles into an adult-like anaerobic pattern.
              Perturbations in the microbiome are associated with susceptibility
              to autoimmune diseases, such as diabetes, inflammatory bowel
              disease, atopy, and other conditions. The mechanisms of
              acquisition and progression toward a “normal” infant microbiome
              are poorly understood.
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                {" "}
                How it works:{" "}
              </span>{" "}
              Take a pic from your mobile of Diaper Load and get the detail,
              alert, alarm and nearest hospital.
              <div style={divider}></div>
              <span style={bigPara}> 7) www.fresh5.pk:</span> When starting a We
              are a unique online grocer in Pakistan, delivering premium quality
              fresh-from-the-farm produces and brand-name & best quality
              groceries to you! We always love our customers! We are in
              discussions with grocers in the United States to augment their
              supply delivery systems. Farmers, grocers, restaurants, food
              banks, and consumers WIN
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                {" "}
                Business Model:{" "}
              </span>
              <br />
              o Business to Business
              <br />
              o Business to Consumer
              <br />o Business to Business to Consumer
              <div style={divider}></div>
              <span style={bigPara}> 8) GOTCHA!! (Second Screen):</span>{" "}
              Integrate Social Commerce Platform with Semanitc Sales & Marketing
              and ACR. Analyzes real time intelligence and proposes placement
              for targeted customers building brand equity.
              <br />
              Title of Invention: System and Method for Providing Second Screen
              Content Features, Tracking and Analysis with geographical
              independence. (US Patent EFS ID 26495963 Application No:
              62368479).
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                9) NextGen POS: (Federal Board of Revenue Pakistan Integrated
                Point of Sales)
              </span>{" "}
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                {" "}
                Why NextGen POS? <br />
              </span>
              o It ensures the compliance of SRO 180(1)/2019 and SRO 1360
              <br />
              o No delay in filing the returns
              <br />
              o Highly compatible for every ADX user
              <br />
              o Solution for individuals, intermediaries and businesses
              <br />
              o 360 solution with various features: Desktop based POS, Web based
              POS
              <br />
              o Need fulfillment by NextGen POS:
              <br />
              o Customer/Vendor management
              <br />
              o FBR integration
              <br />
              o Inventory tracking
              <br />
              o NIC details management
              <br />o Secure
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                10) Digital Interactive Learning and Training Platform:
              </span>{" "}
              We have created a state-of-the-art, web based interactive training
              and communication platform that delivers an engaging learning
              experience. <br />
              By using full-motion, interactive courseware, we teach people the
              skills necessary to improve and be more productive and in this
              case to love reading.
              <br />
              When you get a chance you may experience from our learning
              provided learning material, where you will find the answer to most
              of your question about the program, in this way you will be able
              to know more and understand better how we can collaborate
              together.
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                11) Offline Communication: (Offline Messaging, Gaming, SOS &
                IoT)
              </span>{" "}
              <div style={divider}></div>
              <span style={bigPara}>12) Brain Computer Interface: </span>
              To Enter the New Technology of “Gaming” for the sake of “Disabled
              Person”, we want them to operate like Normal Person. This is our
              aspect of using BCI.
              <br />
              <span
                style={{ color: "#1c74b9", fontSize: 18, fontWeight: "bold" }}
              >
                {" "}
                Applications of BCI:{" "}
              </span>
              <br />
              o Robotic Arms
              <br />
              o Wheel Chairs <br />
              o Switch Buttons <br />
              o Games etc
              <br />
              o Testing the working of BCI "Frog Game Control":
              <br />
              o Jumping of a frog is controlled by Eye Blinking
              <br />
              o Data Collection
              <br />
              o Actions generating using SDKs
              <br />
              o Sending Commands
              <br />o Game running and receiving Commands
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                13) Autonomous Drone: (AI, ML based){" "}
              </span>{" "}
              Can work at Infranet (Offline): Over the next year, the drone team
              investigated different approaches to vehicle design, built
              prototypes, and ran experiments and test flights. The goal was to
              learn as much as possible, as quickly as possible, to improve the
              safety systems and precise navigation required to operate in the
              congested modern world.
              <div style={divider}></div>
            </p>
            <br />
            <Button onClick={() => history.goBack()} style={{ width: 150 }}>
              Back{" "}
            </Button>
          </Jumbotron>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default MoreIP;
