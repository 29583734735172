import React, { useState } from "react";
import { Container, Jumbotron, Button, Row, Col } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import "../App.css";

function AITech() {
  const line = {
    width: 200,
    height: 3,
    background: "black",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
  };

  const content = {
    marginTop: 30,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 500,
  };
  const contentTech = {
    // marginTop: 30,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 500,
  };
  const divider = {
    width: "90%",
    height: 0.5,
    background: "lightgrey",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    marginTop: 10,
  };
  const history = useHistory();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container>
          <Jumbotron
            style={{
              background: "white",
              marginTop: 40,
            }}
          >
            <h1
              style={{
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Intellectual Properties
            </h1>
            <div style={line}></div>

            <div style={content}>
              <ul>
                <Row style={{ padding: 5 }}>
                  <Col sm>
                    <li> Assimilate Data </li>
                    <li> Engineer a faster, simpler approach </li>
                  </Col>

                  <Col sm>
                    <li>Analyze Weak Touch Points </li>
                    <li> Enable AI, ML, NLP Data Decisions </li>
                    <li> Use Intelligence </li>
                  </Col>
                </Row>
              </ul>
            </div>
            <div style={divider}></div>
            <br />
            <h3
              style={{
                fontWeight: "700",
                textAlign: "center",
                color: "#1c74b9",
              }}
            >
              Our Technologies
            </h3>
            <br />
            <div style={contentTech}>
              <ol>
                <Row style={{ padding: 5 }}>
                  <Col sm>
                    <li> Aviation Intelligence </li>
                    <li> AI Driven E - Commerce </li>
                    <li> Semantic Sales & Marketing using NLP </li>
                    <li> AI Healthcare </li>
                    <li> AI Baby & Mother care </li>
                  </Col>

                  <Col sm>
                    <li>Automated Content Recognition </li>
                    <li> Industry 4.0 </li>
                    <li> AI Based performing Arts </li>
                    <li> AI Fashion Designing </li>
                  </Col>
                </Row>
              </ol>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => history.push("/more-intellectual-properties")}
              >
                Learn more on properties
              </Button>
            </div>
          </Jumbotron>
        </Container>
      </div>
    </>
  );
}

export default AITech;
