import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";
import "./App.css";

import Navigation from "./components/NavBar";
import Landingpage from "./components/LandingPage";
import ExecutiveTeam from "./components/ExecutiveTeam";
import IntellectualProperties from "./components/IntellectualProperties";
import MoreIP from "./components/MoreIP";
import Solutions from "./components/Solutions";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/About";
import Consultancy from "./components/Consultancy";
import StrategicPartners from "./components/StrategicPartners";
import Clients from "./components/Clients";
import Industry from "./components/Industry";
import Footer from "./components/Footer";
import MoreConsultancy from "./components/MoreConsultancy";
import MoreERP from "./components/MoreERP";

import LoadingScreen from "react-loading-screen";
import LoadingImage from "./components/assets/logo.png";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    Aos.init({ duration: 1000 });
    setTimeout(() => this.setState({ loading: false }), 2000);
  }

  render() {
    const { loading } = this.state;

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/home" component={Landingpage} />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/erp" component={Solutions} />
          <Route exact path="/consultancy" component={Consultancy} />
          <Route exact path="/clients" component={Clients} />
          <Route exact path="/industry" component={Industry} />
          <Route
            exact
            path="/strategic-partners"
            component={StrategicPartners}
          />{" "}
          <Route exact path="/more-consultancy" component={MoreConsultancy} />
          <Route exact path="/more-nexgenerp" component={MoreERP} />
          <Route
            exact
            path="/more-intellectual-properties"
            component={MoreIP}
          />
          <LoadingScreen
            loading={loading}
            bgColor=" white"
            spinnerColor="#1C74B9"
            textColor="#404042"
            logoSrc={LoadingImage}
            text=""
          >
            <Navigation />

            <div data-aos="zoom-in-up">
              <Landingpage />
            </div>
            {/* <div id="executive-team" data-aos="zoom-in-up">
              <ExecutiveTeam />
            </div> */}
            <div id="about-us" data-aos="zoom-in-up">
              <AboutUs />
            </div>
            <div id="solution-erp" data-aos="zoom-in-up">
              <Solutions />
            </div>
            <div id="industry-4" data-aos="zoom-in-up">
              <Industry />
            </div>
            <div id="iproperties" data-aos="zoom-in-up">
              <IntellectualProperties />
            </div>

            <div id="consultancy" data-aos="zoom-in-up">
              <Consultancy />
            </div>
            <div id="strategic-partners" data-aos="zoom-in-up">
              <StrategicPartners />
            </div>
            <div id="clients" data-aos="zoom-in-up">
              <Clients />
            </div>
            <div id="contact-us" data-aos="zoom-in-up">
              <ContactUs />
            </div>
            <Footer />
          </LoadingScreen>
        </Switch>
      </BrowserRouter>
    );
  }
}
export default App;
