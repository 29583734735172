import React from "react";
import { Jumbotron, Container, Row, Col, Button, Image } from "react-bootstrap";

import "../App.css";

function Clients() {
  const centerTexts = {
    background: "white",
  };

  const mainHeader = {
    fontWeight: "700",
    // color: "whitesmoke",
    textAlign: "center",
  };
  const sideHeader = {
    fontWeight: "700",
    color: "#1C74B9",
    // color: "whitesmoke",
  };
  const paragraphs = {
    fontSize: "18px",
    fontWeight: "500",
    // color: "whitesmoke",
  };
  const line = {
    width: 200,
    height: 3,
    background: "black",
    marginLeft: "auto",
    marginRight: "auto",
  };
  const content = {
    marginTop: 20,
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 500,
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <Container>
      <Jumbotron
        style={{
          background: "white",
        }}
      >
        <h1
          style={{
            fontWeight: "700",
            // color: "whitesmoke",
            textAlign: "center",
          }}
        >
          Our Clients
        </h1>
        <div style={line}></div>
      <br />
        <h3 style={{ fontWeight: 600, color: "#1C74B9" }}>Our ERP Clients</h3>

        <div style={content}>
          <Row style={{ padding: 5 }}>
            <ol>
              <Col sm>
                <h5 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  Local Clients:
                </h5>
                <li> Meximp Technologies </li>
                <li>Yoca (Pvt ) Ltd</li>
                <li> Sara Industries (Pvt ) Ltd </li>
                <li> Future Technologies (Pvt ) Ltd</li>
                <li>Mario Technologies (Pvt ) Ltd</li>
                <li> Dr . Saleem and Co. </li>
                <li> Unique Poultry International (Pvt ) Ltd </li>
                <li>NDS Technologies </li>
                <li> Image Graphic Solutions </li>
              </Col>
            </ol>{" "}
            <ol>
              <Col sm>
                <h5 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  Foreign Clients:
                </h5>

                <li> Clearwater Systems </li>
                <li> Urbia Furniture</li>
                <li>Sunnywood Inc . </li>
              </Col>
            </ol>
          </Row>
        </div>
        <br />
        <h3 style={{ fontWeight: 600, color: "#1C74B9" }}>
          Our Associated Clients
        </h3>

        <div style={content}>
          <Row style={{ padding: 5 }}>
            <ul>
              <Col sm>
                <h5 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  Public Sector
                </h5>
                <li> Water and sanitation Authority </li>
                <li> GENCO-III</li>
              </Col>
            </ul>
            <ul>
              <Col sm>
                <h5 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  Textile Sector
                </h5>

                <li>Zahur Textile Mills Limited </li>
                <li> Sigma Knitting Mills Ltd</li>
              </Col>
            </ul>
          </Row>

          <Row style={{ padding: 5 }}>
            <ul>
              <Col sm>
                <h5 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  Service Sector
                </h5>
                <li> Techlogix Pakistan (Pvt.) Ltd. </li>
                <li> Pak School of Fashion Design </li>
              </Col>
            </ul>
            <ul>
              <Col sm>
                <h5 style={{ fontWeight: 600, color: "#1C74B9" }}>
                  Manufacturing Sector
                </h5>

                <li>Pioneer Cement Limited </li>
                <li> Eden Developers (Pvt.) Ltd </li>
              </Col>
            </ul>
          </Row>
        </div>
      </Jumbotron>
    </Container>
  );
}

export default Clients;
