import React, { useState } from "react";
import { Jumbotron, Container, Form, Button, Col } from "react-bootstrap";
import { send } from "emailjs-com";

import "../App.css";
import { init } from "emailjs-com";
init("user_7ItIztYsRR1P4xNkXT9g6");

function ContactUs() {
  const centerTexts = {
    background: "white",
    width: "100%",
  };
  const line = {
    width: 150,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 30,
  };
  const mainHeader = {
    fontWeight: "700",
    color: "#404042",
    textAlign: "center",
  };
  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      "service_j81p4ke",
      "5corenextgen-backend",
      toSend,
      "user_7ItIztYsRR1P4xNkXT9g6"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
      })
      .catch((err) => {
        console.log("FAILED!", err);
      });
    e.target.reset();
    alert("Message Succesfully Sent");
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div className="first-page">
      <Container style={{ paddingTop: 30 }}>
        <Jumbotron style={centerTexts}>
          <h1 style={mainHeader}> Contact Us </h1>
          <div style={line}></div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d905.3545232241187!2d67.07164182916695!3d24.815371816278645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33c60ee704e0b%3A0x4a72405f6d88326a!2sLadiesFirst!5e0!3m2!1sen!2s!4v1619378888404!5m2!1sen!2s"
            style={{
              width: "100%",
              height: 350,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 15px #aaa",
              borderRadius: 5,
              overflow: "hidden",
              border: "none",
            }}
          ></iframe>

          <br />
          <div className="contact-container" id="contact">
            <Form
              onSubmit={onSubmit}
              style={{
                padding: 20,
                borderRadius: 5,
                height: 400,
              }}
            >
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    // value={toSend.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Your full name"
                    // value={toSend.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  name="subject"
                  placeholder="Your Message"
                  // value={toSend.subject}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter message"
                  style={{ height: 100 }}
                  required
                  name="message"
                  onChange={handleChange}
                />
              </Form.Group>
              <br />
              <Button
                type="submit"
                className="btn"
                style={{ width: 150, marginBottom: 100 }}
              >
                Send Mail{" "}
              </Button>
            </Form>
          </div>
        </Jumbotron>
      </Container>
    </div>
  );
}

export default ContactUs;
