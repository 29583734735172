import React, { useState } from "react";

import { Navbar, Nav, Image } from "react-bootstrap";
import { Link } from "react-scroll";
import { Tooltip } from "@material-ui/core";
import Github from "@material-ui/icons/GitHub";
import Fb from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import Linkedin from "@material-ui/icons/LinkedIn";
import Call from "@material-ui/icons/WhatsApp";
import Menu from "../components/assets/menu.svg";
import Logo from "../components/assets/logoWithoutText.png";
import "../App.css";

// 1) Home
// 2) About us
//      a. Vision, Mission, Values
//      b. Introduction
// 3) Intellectual Properties
// 4) Solutions
// 5) Consultancy
// 6) Contact Us

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false,
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  setNavClose = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    const navdesign = {
      background: "#404042",
      paddingLeft: 10,
      marginBottom: 20,
      width: "100%",
    };
    const logo = {
      fontSize: "26px",
      fontWeight: "600",
      display: "flex",
      alignItems: "center",
      color: " #1C74B9",
      paddingTop: 20,
      marginLeft: 20,
      paddingBottom: 10,
    };
    const navlinks = {
      fontSize: "14px",
      padding: "8px",
      margin: "5px",
      color: "white",
      borderRadius: "3px",

      fontWeight: "500",
      cursor: "pointer",
      textAlign: "center"   };
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div style={{ width: "max-content" }}>
              <Navbar.Brand href="/" style={logo}>
                <Image
                  src={Logo}
                  style={{ width: 55, height: 50, marginRight: 10 }}
                />
                5CORE
                <span style={{ marginLeft: 5, color: "#E91087" }}> NEXGEN</span>
              </Navbar.Brand>
            </div>

            <Navbar
              collapseOnSelect
              style={navdesign}
              onToggle={this.setNavExpanded}
              expanded={this.state.navExpanded}
              inverse={true}
              expand="sm"
            >
              <Navbar.Toggle className="order-1 ml-auto">
                <Image src={Menu} />
              </Navbar.Toggle>
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                  {/* <Link
                    to="executive-team"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Executive Team
                  </Link> */}
                  <Link
                    to="about-us"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    About Us
                  </Link>

                  <Link
                    to="solution-erp"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    NextGen ERP
                  </Link>

                  <Link
                    to="industry-4"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Industry 4.0
                  </Link>

                  <Link
                    to="iproperties"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Intellectual Properties
                  </Link>

                  <Link
                    to="consultancy"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Consultancy
                  </Link>

                  <Link
                    to="strategic-partners"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Strategic Partners
                  </Link>

                  <Link
                    to="clients"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Clients
                  </Link>

                  <Link
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    style={navlinks}
                    className="navlink-hover"
                    onClick={this.setNavClose}
                  >
                    Contact Us
                  </Link>
                </Nav>
              </Navbar.Collapse>
              <div className="order-md-0 mr-auto order-0">
                <Tooltip title="Facebook">
                  <Fb
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href =
                        "https://www.facebook.com/5cnextgensol/")
                    }
                  />
                </Tooltip>
                <Tooltip title="Instagram">
                  <Instagram
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://instagram.com/")
                    }
                  />
                </Tooltip>
                <Tooltip title="Linkedin">
                  <Linkedin
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://www.linkedin.com/")
                    }
                  />
                </Tooltip>
                <Tooltip title="Whatsapp">
                  <Call
                    className="social-links"
                    onClick={(event) =>
                      (window.location.href = "https://wa.me/+923333247535")
                    }
                  />
                </Tooltip>
              </div>
            </Navbar>
          </div>
        </div>
      </div>
    );
  }
}
export default NavBar;
