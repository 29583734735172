import React from "react";
import { useHistory } from "react-router-dom";

import { Container, Jumbotron, Button } from "react-bootstrap";
import InsideNavigation from "./InsideNavBar";
import Footer from "./Footer";
import "../App.css";

function MoreConsultancy() {
  const line = {
    width: 250,
    height: 3,
    background: "#404042",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
  };
  const divider = {
    width: "90%",
    height: 0.5,
    background: "lightgrey",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
    marginTop: 10,
  };

  const paragraphs = {
    fontSize: 18,
    fontWeight: 500,
  };
  const bigPara = {
    fontSize: 21,
    color: "#1c74b9",
    fontWeight: 600,
  };
  const history = useHistory();

  return (
    <>
      <InsideNavigation />
      <div
        className="second-page"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container style={{ paddingTop: 170 }}>
          <Jumbotron
            style={{
              background: "white",
            }}
          >
            <h1
              style={{
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Professional Consultancy
            </h1>

            <div style={line}></div>

            <br />
            <p style={paragraphs}>
              <span style={bigPara}> 1) Accounting Services: </span> Accounting
              services relate to assistance in bookkeeping & supervision in
              maintenance of accounting records according to International
              Accounting Standards (or Generally Accepted Accounting Principles)
              and International Financial Reporting Standards. This also
              includes preparation of financial statements, budgets, cash flows,
              and inventory reports. These services include:
              <br />
              o Accounting & Financial Reporting
              <br />o Online Book keeping Services
              <div style={divider}></div>
              <span style={bigPara}> 2) Risk Management: </span> 5Core NextGen
              advises its clients to focus on the risks inherent in the
              technological advancement to support business objectives. We
              assist in identifying & managing these risks as under:
              <br />
              o Systems Control Review
              <br />
              o Information security Review
              <br />
              o Documentation of Planning o E-Commerce Review
              <br />
              o Business Continuity Review and
              <br />o Fraud Risk Management.
              <div style={divider}></div>
              <span style={bigPara}> 3) Taxation Services: </span> The range of
              services provided by the Firm includes the following:
              <br />
              o Income Tax & Related Services
              <br />
              o Sales Tax & Related Services
              <br />
              o VAT & Related Services
              <br />
              o Transaction Tax
              <br />o Tax Investigation Services
              <div style={divider}></div>
              <span style={bigPara}> 4) Financial Advisory Services: </span> We
              provide comprehensive financial and strategic advice to various
              business enterprises. Some of these services are:
              <br />
              o Pre-feasibility Studies oFeasibility Study
              <br />
              o Mergers & Acquisitions
              <br />
              o Valuations oFraud Investigations oFinance Raising/ Project
              Funding
              <br />
              o Loan Swaps
              <br />o Placement of Funds, (Both Short & Long Term- Including
              Local & Foreign
              <div style={divider}></div>
              <span style={bigPara}> 5) Management Consultancy: </span> The
              management consulting is the core service of 5Core. The success in
              management consulting is the result of extensive experience of
              different assignments in various fields. These includes:
              <br />
              o Systems Development and Reviews: Management Information System,
              Standard Operating Procedures, Development of conceptual business
              models and Financial & Accounting systems manuals.
              <br />
              o SME Income Estimation o Business Development Services
              <br />o Reorganization Services: We partner provide: Diagnostic
              review of existing services & function, Cost reduction and
              operating efficiency studies for profitable & loss making units,
              Restructuring of business, And after in-depth analysis of the
              above, turnaround strategy is designed to reorganize the business.
              <div style={divider}></div>
              <span style={bigPara}>
                {" "}
                6) Information Technology Consultancy:{" "}
              </span>{" "}
              5Core due to its experience in Information Technology advises
              organizations on how best to use IT in achieving their business
              objectives. In addition to providing advice, we also provide
              strategic guidance to clients with regard to technology, IT
              infrastructures and enabling major business processes through
              enhancements to IT. We also provide services in conjunction with
              renowned IT companies for estimating, managing, implementing,
              deploying, and administering IT systems on behalf of our client
              organizations.
              <div style={divider}></div>
              <span style={bigPara}> 7) Startup Assistance: </span> When
              starting a business, most entrepreneurs do not have the expertise
              across all areas of the business. Even if that would be the case,
              it would not be beneficial. Necessary tasks to start a business
              can be accomplished successfully and more efficiently with help.
              Startups have a complex job ahead of them. After the legal
              business structure is in place, business owners have to manage a
              range of areas to succeed. Business startup consulting services
              help with these areas. They provide the often much needed support
              to entrepreneurs and partners.
              <br />
              Startup Help:
              <br />
              o Gain Clarity & Objectivity o Gain Knowledge & Expertise
              <br />
              o Help with Research
              <br />
              o Planning & Tasks
              <br />
              o Vendor Sourcing
              <br />
              o Project Pricing
              <br />
              o Business Branding
              <br />
              o Website Design & E-commerce o Marketing Campaigns
              <br />
              o Advertising & Print
              <br />
              o Sales Efforts
              <br />
              o Graphic Design
              <br />
              o Product Development
              <br />o Packaging
              <div style={divider}></div>
              <span style={bigPara}> 8) SMEs Assistance: </span> Small business
              consulting clients have tremendous flexibility, access to
              expertise, experience and without long-term contracts. Companies
              can hire consultants for a variety of business needs.
              <br />
              o Get Answers & Clarity
              <br />
              o Access to Intelligence & Expertise
              <br />
              o Help with Projects
              <br />
              o Vendor Management
              <br />
              o Online Marketing Help
              <br />
              o Advertising Assistance
              <br />
              o Graphic Design Support
              <br />
              o E-commerce Development
              <br />
              o Website Design
              <br />
              o Sales & Distribution
              <br />
              o Product Design
              <br />
              o Packaging
              <br />
              o Public Relations
              <br />o Growth Management
              <div style={divider}></div>
              <span style={bigPara}> 9) Digital Marketing Services: </span> If
              you want to succeed in this age of digital devices with many
              screen sizes, you can't ignore digital as a core element or your
              business process. As rightly said by someone, go digital or go
              home… We provide professional digital marketing services to give
              you the best digital exposure you deserve. We strongly believe
              that going digital is the way forward, for any business now, and
              we try to reflect our beliefs through dedication in our services.
              We make your brand available to your potential customers at the
              right time and on the right screen. Our winning solutions and
              experience have helped many of our clients to interact and engage
              with their customers in a new better way. We aim to do the same
              for you. Also, We develop digital marketing strategies for our
              clients that result in targeted, relevant, highly customized and
              costeffective solutions that deliver measurable returns on your
              investments. We understand your business and we understand your
              customer.
            </p>
            <br />
            <Button onClick={() => history.goBack()} style={{ width: 150 }}>
              Back{" "}
            </Button>
          </Jumbotron>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default MoreConsultancy;
